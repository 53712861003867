// Article List
@media screen and (min-width: 769px) {
    .article-listing, .article-page {
        display: grid;
        grid-gap: 20px;
        grid-template-columns: calc(100% - 320px) 300px;
    }
    .article-page {
        margin-bottom: 20px;
        article {
            display: block;
            width: 100%;
        }
    }
    .sidebar {
        grid-column: 2 / span 1;
    }
    .main-content {
        grid-column: 1 / span 1;
        padding: 0 20px;
    }
}

.article-list {
    @media screen and (min-width: 769px) {
        // max-width: calc(100% - 320px);
        grid-column: 1 / span 1;
    }
    @media screen and (min-width: 768px) {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        // float: left;
    }
    width: 100%;
    article {
        margin: 20px 0;
        word-wrap: break-word;
        @media screen and (min-width: 768px) {
            width: calc((100% - 60px) / 3);
            margin: 0;
            margin-right: 30px;
            margin-bottom: 20px;
            &:nth-of-type(3n) {
                margin-right: 0;
            }
        }
        padding-bottom: 20px;
        border-bottom: 1px solid $default;
        a {
            color: $default;
            &:hover {
                color: $default;
            }
        }
        p {
            margin-bottom: 0;
        }
        img {
            width: 100%;
            height: auto;
            margin-bottom: 15px;
        }
    }
    h4 {
        font-weight: 600;
    }
}

// More From Btn
.more-from-btn {
    width: 100%;
    padding: 20px 0;
    text-align: center;
    margin-top: 20px;
    button, a {
        background: $default;
        color: $white;
        border: 0 none;
        padding: 20px;
        width: 300px;
        cursor: pointer;
        @include trans();
        &:hover {
            background: $red;
        }
    }
}

// Search Results
.article-list-full {
    @media screen and (min-width: 769px) {
        // max-width: calc(100% - 320px);
        grid-column: 1 / span 1;
    }
    @media screen and (min-width: 768px) {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        // float: left;
    }
    width: 100%;
    article {
        margin: 20px 0;
        word-wrap: break-word;
        @media screen and (min-width: 768px) {
            width: calc((100% - 90px) / 4);
            margin: 0;
            margin-right: 30px;
            margin-bottom: 20px;
            &:nth-of-type(4n) {
                margin-right: 0;
            }
        }
        padding-bottom: 20px;
        border-bottom: 1px solid $default;
        p {
            margin-bottom: 0;
        }
        img {
            width: 100%;
            height: auto;
            margin-bottom: 15px;
        }
    }
}

.no-results {
    text-align: center;
    img {
        margin: 20px auto;
    }
    p {
        @include rem('font-size', 20);
    }
}

// Search Results
.search-page {
    margin-top: 20px;
    h1 {
        @include rem('font-size', 20);
        margin-bottom: 20px;
    }
}

.search-results {
    @media screen and (min-width: 768px) {
        display: grid;
        grid-template-columns: repeat(4, calc((100% - 60px) / 4));
        grid-gap: 20px;
    }
    h3 {
        border-bottom: 0 none;
        text-transform: none;
    }
}

// Offline message
.offline {
    svg {
        vertical-align: middle;
        margin-right: 10px;
    }
}
.paged {
    h1 {
        width: 100%;
    }
}